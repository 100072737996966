import { useEffect, useRef, useState } from "react";
import styles from "./LabelInput.module.scss";
import { v4 as uuidv4 } from "uuid";

const LabelInput = ({
  label,
  value,
  onChange,
  options = [],
  className = "",
  ...props
}) => {
  const id = uuidv4();
  const inputRef = useRef(null);
  const [labels, setLabels] = useState();

  useEffect(() => {
    if (!value) {
      setLabels([]);
      return;
    }

    setLabels(value);
  }, [value]);

  useEffect(() => {
    if (onChange) {
      onChange(labels);
    }
  }, [labels]);

  const handleOnBlur = (e) => {
    const { value } = e.target;
    inputRef.current.value = "";

    if (!value) {
      return;
    }

    const searchValue = value.trim().toLowerCase();
    if (labels.some((x) => x === searchValue)) {
      return;
    }

    const existingLabel = labels.find(
      (label) => label.toLowerCase() === searchValue
    );

    if (!!existingLabel) {
      setLabels((labels) => [...labels, existingLabel]);
      return;
    }

    setLabels((labels) => [...labels, value]);
  };

  const removeLabel = (label) => {
    setLabels((labels) => labels.filter((l) => l !== label));
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
      handleOnBlur(e);
    }
  };

  return (
    <div
      className={`${styles["label-input"]} label-input ${
        labels?.length && styles["has-value"]
      }`}
    >
      <label htmlFor={id}>{label}</label>

      <div className={styles.values}>
        <div className={styles.labels}>
          {labels?.length
            ? labels.map((label, idx) => (
                <span
                  key={idx}
                  className={styles.label}
                  onClick={() => removeLabel(label)}
                >
                  {label}
                </span>
              ))
            : null}

          <input
            type="text"
            onBlur={handleOnBlur}
            onKeyUp={onEnter}
            ref={inputRef}
            placeholder={props.placeholder ?? "Toevoegen..."}
          />
        </div>
      </div>
    </div>
  );
};

export default LabelInput;
