import { useEffect, useMemo, useState } from "react";
import styles from "./Media.module.scss";
import ImdbService from "../../shared/services/ImdbService";
import { useParams } from "react-router-dom";
import Loader from "../../shared/components/loader/Loader";
import { useDispatch } from "react-redux";
import { setSearchValue } from "../../features/SearchSlice";
import MediaService from "../../shared/services/MediaService";
import Similars from "./similars/Similars";
import DownloadSettings from "./download-settings/DownloadSettings";

const Media = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [item, setItem] = useState(false);
  const [downloads, setDownloads] = useState([]);
  const [isWatched, setIsWatched] = useState(false);
  const { id } = useParams();
  const imdbService = useMemo(() => new ImdbService(), []);
  const mediaService = useMemo(() => new MediaService(), []);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoaded(false);

    if (!id) {
      setItem(null);
      return;
    }

    imdbService.titleById(id).then(async (data) => {
      dispatch(setSearchValue(data.title));

      setItem(data);
    });
  }, [id, imdbService, dispatch]);

  useEffect(() => {
    if (!item) {
      return;
    }

    imdbService.internal(item.id).then((data) => {
      if (!data) {
        setIsWatched(false);
        setDownloads([]);
        return;
      }

      setIsWatched(data.isWatched);
      setDownloads(data.downloads);
      setIsLoaded(true);
    });
  }, [item, imdbService]);

  if (!isLoaded) {
    return <Loader />;
  }

  const handleAddWatch = () => {
    mediaService.create(id).then((data) => {});
    setIsWatched(true);
  };

  const handleUnDelete = () => {
    mediaService.delete(id).then((data) => {});
    setIsWatched(false);
  };

  return (
    <div className={styles.media}>
      <div className={`${styles.footer} mobile-only`}>
        <Similars items={item.similars} />
      </div>

      <div className={styles.left}>
        <div className={styles.image}>
          <img src={item.image} alt={item.title} />

          {!isWatched && (
            <button className={styles["watch-btn"]} onClick={handleAddWatch}>
              add
            </button>
          )}

          {isWatched && (
            <button className={styles["watch-btn"]} onClick={handleUnDelete}>
              done
            </button>
          )}
        </div>

        <DownloadSettings
          item={item}
          downloads={downloads}
          setDownloads={setDownloads}
          isWatched={isWatched}
          setIsWatched={setIsWatched}
        />
      </div>

      <div className={styles.right}>
        <div className={styles.image}>
          <img src={item.image} alt={item.title} />

          {!isWatched && (
            <button className={styles["watch-btn"]} onClick={handleAddWatch}>
              <span className="material-symbols-outlined">add</span>
            </button>
          )}

          {isWatched && (
            <button className={styles["watch-btn"]} onClick={handleUnDelete}>
              <span className="material-symbols-outlined">done</span>
            </button>
          )}
        </div>
        <div className={styles.plot}>
          <div className={styles.description}>{item.plot}</div>
          <div className={styles.tags}>
            <div className={styles.tag}>
              <div className={styles.name}>Releasedatum</div>
              <div className={styles.value}>
                {new Date(item.releaseDate).toLocaleDateString("nl-NL")}
              </div>
            </div>
          </div>
        </div>
        <div className={styles["genres-score"]}>
          <div className={styles.genres}>
            {item.genreList?.length
              ? item.genreList.map((genre, index) => (
                  <span key={index} className={styles.genre}>
                    {genre.value}
                  </span>
                ))
              : null}
          </div>
          {item.imDbRating && (
            <div className={styles.score}>{item.imDbRating}</div>
          )}
        </div>
      </div>

      <div className={`${styles.footer} desktop-only`}>
        <Similars items={item.similars} />
      </div>
    </div>
  );
};

export default Media;
