import ApiService from "./ApiService";

class MediaService {
  constructor() {
    this.api = new ApiService(`${process.env.REACT_APP_API_URL}/MediaItems`);
  }

  get = async () => {
    return await this.api.get();
  };

  create = async (id) => {
    return await this.api.post(`/Create/${id}`, {});
  };

  delete = async (id) => {
    return await this.api.delete(`/${id}`);
  };

  my = async () => {
    return await this.api.get(`/My`);
  };

  downloads = async (id) => {
    return await this.api.get(`${id}/Downloads/`);
  };

  setDownloads = async (id, downloads) => {
    return await this.api.post(`${id}/Downloads/`, downloads);
  };

  deleteDownloads = async (id) => {
    return await this.api.delete(`${id}/Downloads/`);
  };

  setAlternativeDownload = async (possibleDownloadId) => {
    return await this.api.put(`/PossibleDownloads/${possibleDownloadId}/Use`);
  };
}

export default MediaService;
