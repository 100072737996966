import { Link } from "react-router-dom";
import styles from "./MediaItem.module.scss";

const MediaItem = ({ item, className = "" }) => {
  if (!item) {
    return <></>;
  }

  const getMinimalState = () => {
    if (!item || !item.downloads?.length) {
      return null;
    }

    if (item.downloads.some((x) => x.state === "Unknown")) {
      return "unknown";
    }

    if (item.downloads.some((x) => x.state === "Queued")) {
      return "queued";
    }

    if (item.downloads.some((x) => x.state === "Searching")) {
      return "searching";
    }

    if (item.downloads.some((x) => x.state === "Downloading")) {
      return "downloading";
    }

    if (item.downloads.some((x) => x.state === "Downloaded")) {
      return "downloaded";
    }

    if (item.downloads.some((x) => x.state === "Failed")) {
      return "failed";
    }

    if (item.downloads.some((x) => x.state === "Deleted")) {
      return "deleted";
    }

    if (item.downloads.some((x) => x.state === "Paused")) {
      return "paused";
    }

    if (item.downloads.some((x) => x.state === "Skipped")) {
      return "skipped";
    }

    return "movedtoplex";
  };

  return (
    <div
      key={item.id}
      className={`${styles.item} ${className}`}
      style={{ backgroundImage: `url(${item.image})` }}
    >
      <Link to={`/Media/${item.id}`} />
      <div className={styles.poster}>
        <img src={item.image} alt={item.title} loading="lazy" />
      </div>
      <div className={styles.info}>
        <div className={styles.name}>{item.title}</div>
      </div>

      <span
        className={`${styles.badge} media-icon ${getMinimalState()}`}
      ></span>
    </div>
  );
};

export default MediaItem;
