import styles from "./Input.module.scss";
import { v4 as uuidv4 } from "uuid";

const Input = ({
  label,
  value,
  onChange,
  type = "text",
  className = "",
  ...props
}) => {
  const id = uuidv4();

  const getClassName = () => {
    return `${styles.input} input ${className} ${
      !!value && styles["has-value"]
    }`;
  };

  return (
    <div className={getClassName()}>
      <label htmlFor={id}>{label}</label>
      <input
        type={type}
        className={getClassName()}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        id={id}
        {...props}
      />
    </div>
  );
};

export default Input;
