import ApiService from "./ApiService";

class ScrappersService {
  constructor() {
    this.api = new ApiService(`${process.env.REACT_APP_API_URL}/Scrappers`);
  }

  get = async () => {
    return await this.api.get(`/`);
  };
}

export default ScrappersService;
