import styles from "./MediaGroup.module.scss";
import MediaItem from "../../media/media-item/MediaItem";
import { useEffect, useState } from "react";
import Loader from "../../../shared/components/loader/Loader";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";

const MediaGroup = ({
  title,
  items,
  isLoaded = true,
  className = "",
  ...props
}) => {
  const [_isLoaded, setLoaded] = useState(isLoaded);

  useEffect(() => {
    setLoaded(isLoaded);
  }, [isLoaded]);

  return (
    <div className={`${styles.group} ${className}`} {...props}>
      <div className={styles.title}>
        {title} <sup>({items?.length ?? 0})</sup>
      </div>

      {_isLoaded ? (
        <div className={styles.items}>
          <ScrollingCarousel>
            {items?.length ? (
              items.map((item) => (
                <MediaItem key={item.id} item={item} className={styles.item} />
              ))
            ) : (
              <div className={styles["no-items"]}>Geen items gevonden</div>
            )}
          </ScrollingCarousel>
        </div>
      ) : (
        <div className={styles.items}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default MediaGroup;
