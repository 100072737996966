import ApiService from "./ApiService";

class ImdbService {
  constructor() {
    this.api = new ApiService(`${process.env.REACT_APP_API_URL}/Imdb`);
  }

  search = async (title) => {
    return await this.api.get(`/Search/${title}`);
  };

  titleById = async (id) => {
    return await this.api.get(`/Title/${id}`);
  };

  youtubeTrailer = async (id) => {
    return await this.api.get(`/YoutubeTrailer/${id}`);
  };

  inTheaters = async () => {
    return await this.api.get(`/InTheaters`);
  };

  boxOffice = async () => {
    return await this.api.get(`/BoxOffice`);
  };

  comingSoon = async () => {
    return await this.api.get(`/ComingSoon`);
  };

  personById = async (id) => {
    return await this.api.get(`/Person/${id}`);
  };

  episodes = async (id, season) => {
    return await this.api.get(`/Episodes/${id}/${season}`);
  };

  internal = async (id) => {
    return await this.api.get(`Title/${id}/Internal`);
  };
}

export default ImdbService;
