import styles from "./TableHeaderFilters.module.scss";
import { useEffect, useRef, useState } from "react";
import useClickOutside from "../../../../hooks/useClickOutside";

const TableHeaderFilters = ({
  column,
  data,
  filterChanged,
  isAlignLeft = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openFilter, setFilter] = useState(null);
  const [selectedOptions, setOptionSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const ref = useRef(null);

  useClickOutside(
    ref,
    () => {
      setIsOpen(false);
    },
    isOpen
  );

  useEffect(() => {
    if (!column || !column.filters?.length) {
      setFilter(null);
      return;
    }

    setFilter(column.filters[0]);
  }, [column]);

  useEffect(() => {
    if (!openFilter) {
      return;
    }

    const filterOptions = [];
    data.forEach((element) => {
      const value = openFilter.value(element);
      const display = openFilter.display(element);

      if (filterOptions.some((x) => x.value === value)) {
        return;
      }

      filterOptions.push({
        value,
        display,
        selected: false,
        filter: openFilter,
      });
    });

    setOptions(filterOptions);
    setSearchValue(null);
  }, [openFilter]);

  useEffect(() => {
    if (!searchValue) {
      setFilteredOptions(options);
      return;
    }

    setFilteredOptions(
      options.filter((x) => x.display.toLowerCase().indexOf(searchValue) > -1)
    );
  }, [options, searchValue]);

  useEffect(() => {
    filterChanged(selectedOptions);
  }, [selectedOptions]);

  const selectOption = (option) => {
    option.selected = !option.selected;
    if (option.selected) {
      setOptionSelected((prev) => [
        ...prev,
        { ...option, filterName: openFilter.name },
      ]);
      return;
    }

    setOptionSelected((prev) =>
      prev.filter(
        (x) => x.filterName === openFilter.name && x.value !== option.value
      )
    );
  };

  const searchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const selectAll = (e) => {
    const selected = e.target.checked;
    filteredOptions.forEach((x) => {
      x.selected = !selected;
      selectOption(x);
    });
  };

  return (
    <div className={styles.filters}>
      <div
        className={`${styles["filter-icon"]} ${isOpen ? styles.open : ""}`}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span className="material-symbols-outlined">filter_alt</span>
      </div>

      {isOpen && (
        <div
          ref={ref}
          className={`${styles["filter-box"]} ${
            isAlignLeft ? styles["align-left"] : null
          }`}
        >
          {column.filters.length > 1 && (
            <ul>
              {column.filters.map((filter) => (
                <li
                  key={filter.name}
                  className={`${styles.tab} ${
                    openFilter === filter ? styles.active : null
                  }`}
                  onClick={() => setFilter(filter)}
                >
                  {filter.name}
                </li>
              ))}
            </ul>
          )}

          <div className={styles.search}>
            <input
              type="text"
              placeholder="Zoeken..."
              onChange={searchChange}
            />
          </div>

          <div className={styles.options}>
            <div className={styles.option}>
              <label className={styles["select-all"]}>
                <input
                  type="checkbox"
                  onChange={selectAll}
                  checked={!filteredOptions.some((x) => !x.selected)}
                />{" "}
                Selecteer alles
              </label>
            </div>

            {filteredOptions.map((option, idx) => (
              <div key={idx} className={styles.option}>
                <label>
                  <input
                    type="checkbox"
                    onChange={() => selectOption(option)}
                    checked={option.selected}
                  />{" "}
                  {option.display}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TableHeaderFilters;
