import { useEffect, useState } from "react";
import styles from "./Table.module.scss";
import TableHeader, { SortOrder } from "./table-header/TableHeader";

const Table = ({
  columns,
  data,
  rowFormatters = null,
  noData = "Geen data gevonden...",
  ...props
}) => {
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState(SortOrder.NONE);
  const [sortedData, setSortedData] = useState(data);
  const [filters, setFilters] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const sortData = (data) => {
    if (!sortField || sortOrder === SortOrder.NONE) {
      return data;
    }

    const sortedData = [...data];
    if (sortField) {
      sortedData.sort((a, b) => {
        const aValue = sortField.sort.value(a)?.toString() ?? "";
        const bValue = sortField.sort.value(b)?.toString() ?? "";

        const compare = aValue.localeCompare(bValue, undefined, {
          numeric: true,
          sensitivity: "base",
        });

        if (compare === 0) {
          return 0;
        }

        return sortOrder === SortOrder.ASCENDING ? compare : -compare;
      });
    }

    return sortedData;
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    if (columns.length > 0) {
      const sortableColumns = columns.filter((x) => x.sort && x.sort.sortable);
      if (sortableColumns.length > 0) {
        setSortedData(sortData(filteredData));
      }
    }
  }, [columns]);

  useEffect(() => {
    setSortedData(sortData(filteredData));
  }, [sortField, sortOrder, filteredData]);

  useEffect(() => {
    if (!filters.length || !columns.length) {
      setFilteredData(data);
      return;
    }

    let allFilters = [];
    columns
      .filter((x) => !!x.filters)
      .forEach(
        (x) =>
          (allFilters = [
            ...allFilters,
            ...x.filters.map((f) => ({ ...f, column: x })),
          ])
      );

    let filtered = [];
    data.forEach((row) => {
      let valid = true;
      allFilters.forEach((x) => {
        const filterValues = filters
          .filter((y) => y.filter.name === x.name)
          .map((y) => y.value);

        if (!filterValues?.length) {
          return;
        }

        var columnValue = x.value(row);
        if (!filterValues.some((x) => x === columnValue)) {
          valid = false;
          return;
        }
      });

      if (valid) {
        filtered.push(row);
      }
    });

    setFilteredData(filtered);
  }, [filters]);

  const getTemplateColumns = () => {
    return columns.map((column) => column.width).join(" ");
  };

  const getContent = (column, row) => {
    if (!!column.condition && !column.condition(row)) {
      return null;
    }

    const value = column.value(row);

    switch (column.type) {
      case "link":
        return <a {...value}>{value.text}</a>;
      case "image":
        return <img {...value} />;
      case "icon":
        return <span className="material-symbols-outlined">{value}</span>;
      default:
        return value;
    }
  };

  const getStyles = (column, row) => {
    let styles = {};
    if (column.style) {
      styles = column.style(row);
    }

    return styles;
  };

  const getClassName = (column, row, className = "") => {
    if (column.className) {
      const classes = column.className(row);
      if (classes) {
        className += " " + classes;
      }
    }

    return className;
  };

  const getRowClassName = (className = "", row) => {
    if (rowFormatters) {
      if (rowFormatters.isDisabled && rowFormatters.isDisabled(row)) {
        className += ` ${styles.disabled}`;
      }

      if (rowFormatters.className) {
        const classes = rowFormatters.className(row);
        if (classes) {
          className += `${className} ${classes}`;
        }
      }
    }

    return className;
  };

  const onSortChange = (column, sortOrder) => {
    setSortField(column);
    setSortOrder(sortOrder);
  };

  return (
    <div
      className={styles.table}
      style={{ gridTemplateColumns: getTemplateColumns() }}
    >
      <TableHeader
        columns={columns}
        data={data}
        sortField={sortField}
        sortOrder={sortOrder}
        sortChange={onSortChange}
        filtersChanged={setFilters}
      />
      {sortedData.map((row, index) => (
        <div key={index} className={getRowClassName(styles.row, row)}>
          {columns.map((column, index) => (
            <div
              key={index}
              className={getClassName(
                column,
                row,
                `${styles.cell} ${!column.title && styles["no-right-border"]}`
              )}
              style={getStyles(column, row)}
            >
              {getContent(column, row)}
            </div>
          ))}
        </div>
      ))}

      {!sortedData.length ? (
        <div
          className={styles["no-data"]}
          style={{ gridColumn: `span ${columns.length}` }}
        >
          {noData}
        </div>
      ) : null}
    </div>
  );
};

export default Table;
