import { useEffect, useState } from "react";
import Input from "../../../../shared/components/form-items/input/Input";
import styles from "./DownloadSettingsModal.module.scss";
import LabelInput from "../../../../shared/components/form-items/label-input/LabelInput";
import Select from "../../../../shared/components/form-items/select/Select";
import SearchSettingsService from "../../../../shared/services/SearchSettingsService";
import Loader from "../../../../shared/components/loader/Loader";
import { useModal } from "../../../../shared/components/modal/useModal";

const DownloadSettingsModal = ({ download }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { hideModal } = useModal();
  const [searchQuery, setSearchQuery] = useState(null);
  const [minSeeders, setMinSeeders] = useState(0);
  const [maxSizeInMb, setMaxSizeInMb] = useState(0);
  const [requiredTags, setRequiredTags] = useState([]);
  const [pointTags, setPointTags] = useState([]);
  const [excludeTags, setExcludeTags] = useState([]);

  const searchSettingsService = new SearchSettingsService();
  const [currentSettings, setCurrentSettings] = useState(null);
  const [defaultSettings, setDefaultSettings] = useState(null);

  useEffect(() => {
    if (download && download.searchSettings) {
      setCurrentSettings(download.searchSettings);
      return;
    }

    if (currentSettings) {
      return;
    }

    setIsLoaded(false);
    const fetch = async () => {
      const defaultSettings = await searchSettingsService.default();
      setDefaultSettings(defaultSettings);
      setCurrentSettings(defaultSettings);
    };

    fetch();
  }, [searchSettingsService, download]);

  useEffect(() => {
    if (!currentSettings) {
      return;
    }

    if (!defaultSettings) {
      const fetch = async () => {
        const defaultSettings = await searchSettingsService.default();
        setDefaultSettings(defaultSettings);
      };

      fetch();
    }

    setSearchQuery(download.searchQuery);
    setMinSeeders(currentSettings.minSeeders);
    setMaxSizeInMb(currentSettings.maxDownloadSizeMb);

    setRequiredTags(currentSettings.requiredTags);
    setPointTags(currentSettings.pointTags);
    setExcludeTags(currentSettings.excludeTags);

    setIsLoaded(true);
  }, [currentSettings, download]);

  if (!isLoaded) {
    return <Loader />;
  }

  const addRequired = () => {
    setRequiredTags([...requiredTags, { tags: [], type: null }]);
  };

  const canAddRequired = () => {
    if (requiredTags.length === 0) {
      return true;
    }

    const last = requiredTags[requiredTags.length - 1];
    return !last.tags || !last.type;
  };

  const setRequiredTagType = (idx, type) => {
    const newTags = [...requiredTags];
    newTags[idx].type = type;
    setRequiredTags(newTags);
  };

  const removeRequiredTag = (tag) => {
    setRequiredTags((prev) => prev.filter((t) => t !== tag));
  };

  const removePointTag = (tag) => {
    setPointTags((prev) => prev.filter((t) => t !== tag));
  };

  const onCancel = () => {
    hideModal();
  };

  const onSave = async () => {
    const body = {
      searchQuery,
      minSeeders,
      maxDownloadSizeMb: maxSizeInMb,
      requiredTags,
      pointTags,
      excludeTags,
    };

    await searchSettingsService.saveDownload(download.id, body);
    hideModal();
  };

  return (
    <div className={styles.settings}>
      <div className={styles.form}>
        <Input
          label="Zoek query"
          value={searchQuery}
          onChange={setSearchQuery}
        />

        <div className={styles["seeders-size"]}>
          <Input
            type="number"
            step="1"
            min="0"
            label="Minimale seeders"
            value={minSeeders}
            className={styles.seeders}
            onChange={setMinSeeders}
          />
          <Input
            type="number"
            step="1"
            min="100"
            label="Maximale grootte (MB)"
            value={maxSizeInMb ?? 100}
            className={styles.size}
            onChange={setMaxSizeInMb}
          />

          <div className={styles.calculated}>
            <span>{(maxSizeInMb / 1000).toFixed(2)}GB</span>
          </div>
        </div>

        <h5>Verplichte tags</h5>
        <div className={styles["required-tags"]}>
          {requiredTags.map((tag, idx) => (
            <div key={idx} className={styles.tag}>
              <LabelInput label="Tags" value={tag.tags} />
              <Select
                value={tag.type}
                label="Type"
                options={["AND", "OR"]}
                onChange={(v) => setRequiredTagType(idx, v)}
              />
              <button
                className={`${styles.remove} btn`}
                disabled={requiredTags.length === 1}
                onClick={() => removeRequiredTag(tag)}
              >
                &times;
              </button>
            </div>
          ))}
          <button
            className={`${styles.add} btn`}
            onClick={addRequired}
            disabled={canAddRequired()}
          >
            + Toevoegen
          </button>
        </div>

        <h5>Uitsluit tags</h5>
        <LabelInput label="Tags" value={excludeTags ?? []} />

        <h5>Punten</h5>
        <div className={styles.points}>
          {pointTags.map((tag, idx) => (
            <div key={idx} className={styles.point}>
              <Input label={"Tag"} value={tag.tag} className={styles.tag} />
              <Input
                type="number"
                step="1"
                min="0"
                label={"Punten"}
                value={tag.points}
                className={styles["point-input"]}
              />
              <button
                className={`${styles.remove} btn`}
                disabled={pointTags.length === 1}
                onClick={() => removePointTag(tag)}
              >
                &times;
              </button>
            </div>
          ))}
          <button
            className={`${styles.add} btn`}
            onClick={addRequired}
            disabled={canAddRequired()}
          >
            + Toevoegen
          </button>
        </div>
      </div>

      <div className={styles.buttons}>
        <button className="btn" onClick={onCancel}>
          Annuleren
        </button>
        <button className="btn save" onClick={() => onSave(currentSettings)}>
          Opslaan
        </button>
      </div>
    </div>
  );
};

export default DownloadSettingsModal;
