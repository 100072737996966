import ApiService from "./ApiService";

class UsersService {
  constructor() {
    this.api = new ApiService(`${process.env.REACT_APP_API_URL}/Users`);
  }

  current = async () => {
    return await this.api.get(``);
  };

  getById = async (id) => {
    return await this.api.get(`/${id}`);
  };
}

export default UsersService;
