import { useEffect } from "react";
import styles from "./Select.module.scss";
import { v4 as uuidv4 } from "uuid";

const Select = ({
  label,
  value,
  options,
  onChange,
  placeholder = "",
  className = "",
  ...props
}) => {
  const id = uuidv4();

  const handleOnChange = (e) => {
    const selected = e.target.value;
    if (onChange) {
      onChange(selected);
    }
  };

  return (
    <div
      className={`${styles.select} select ${className} ${
        value && styles["has-value"]
      }`}
    >
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
      <select id={id} defaultValue={value} onChange={handleOnChange} {...props}>
        <option value=""></option>

        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
