import styles from "./Alert.module.scss";

const Alert = ({ children, type, className }) => {
  return (
    <div className={`${styles.alert} ${styles[type]} ${className}`}>
      {children}
    </div>
  );
};

export default Alert;
