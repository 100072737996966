import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
  name: "search",
  initialState: {
    value: "",
  },
  reducers: {
    setSearchValue: (state, action) => {
      state.value = action.payload;
    },
    reset: (state) => {
      state.value = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSearchValue, reset } = searchSlice.actions;

export default searchSlice.reducer;
