import Footer from "./Footer/Footer";
import styles from "./Layout.module.scss";
import Menu from "./Menu/Menu";
import Navbar from "./Navbar/Navbar";

const Layout = ({ children }) => {
  return (
    <div className={styles.layout}>
      <Navbar />
      <Menu />
      <main className={styles.main}>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
