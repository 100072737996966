import { createContext, useReducer } from "react";

export const ModalContext = createContext();

const initialState = {
  title: "",
  content: null,
  showModal: false,
};

const modalReducer = (state, action) => {
  switch (action.type) {
    case "SHOW_MODAL":
      return {
        ...state,
        title: action.payload.title,
        content: action.payload.content,
        callback: action.payload.callback,
        showModal: true,
      };
    case "HIDE_MODAL":
      return {
        ...state,
        showModal: false,
      };
    case "CLEAR_MODAL":
      return {
        showModal: false,
      };
    default:
      return state;
  }
};

export const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, initialState);
  return (
    <ModalContext.Provider value={{ state, dispatch }}>
      {children}
    </ModalContext.Provider>
  );
};
