import ApiService from "./ApiService";

class AgentsInfoService {
  constructor() {
    this.api = new ApiService(`${process.env.REACT_APP_API_URL}/AgentsInfo`);
  }

  get = async () => {
    return await this.api.get();
  };
}

export default AgentsInfoService;
