import styles from "./Similars.module.scss";
import MediaItem from "../media-item/MediaItem";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import { useRef } from "react";

const Similars = ({ items }) => {
  const ref = useRef(null);

  if (!items || !items.length) {
    return <></>;
  }

  const getGridTemplate = () => {
    return `repeat(${items.length}, 110px)`;
  };

  return (
    <div
      ref={ref}
      className={styles.similars}
      style={{ gridTemplateColumns: getGridTemplate() }}
    >
      <h3>
        Vergelijkbaar <sup>({items.length})</sup>
      </h3>
      <ScrollingCarousel>
        {items.map((item) => (
          <MediaItem key={item.id} item={item} className={styles.item} />
        ))}
      </ScrollingCarousel>
    </div>
  );
};

export default Similars;
