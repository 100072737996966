import ApiService from "./ApiService";

class SearchSettingsService {
  constructor() {
    this.api = new ApiService(
      `${process.env.REACT_APP_API_URL}/SearchSettings`
    );
  }

  default = async () => {
    return await this.api.get(`/Default`);
  };

  saveDownload = async (downloadId, body) => {
    return await this.api.put(`/Downloads/${downloadId}`, body);
  };
}

export default SearchSettingsService;
