import Home from "./components/home/Home";
import Media from "./components/media/Media";
import MediaByType from "./components/media/media-by-type/MediaByType";
import Search from "./components/search/Search";

const AppRoutes = [
  {
    index: true,
    element: <Home />,
  },
  {
    path: "/search",
    element: <Search />,
  },
  {
    path: "/media/:id",
    element: <Media />,
  },
  {
    path: "/movies",
    element: <MediaByType type="Movie" />,
  },
  {
    path: "/series",
    element: <MediaByType type="TVSeries" />,
  },
];

export default AppRoutes;
