import { useEffect, useState } from "react";
import styles from "./DownloadSerieModal.module.scss";
import MediaService from "../../../../shared/services/MediaService";
import ImdbService from "../../../../shared/services/ImdbService";
import { useModal } from "../../../../shared/components/modal/useModal";
import DownloadSettingsModal from "../download-settings-modal/DownloadSettingsModal";
import DownloadHistoryModal from "../download-history-modal/DownloadHistoryModal";
import Loader from "../../../../shared/components/loader/Loader";

const DownloadSerieModal = ({ media }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [seasons, setSeasons] = useState([]);
  const [downloads, setDownloads] = useState([]);
  const [selectedEpisodes, setSelectedEpisodes] = useState([]);
  const [blockedEpisodes, setBlockedEpisodes] = useState([]);
  const { hideModal, showModal } = useModal();

  useEffect(() => {
    setIsLoaded(false);
    const imdbService = new ImdbService();
    const mediaService = new MediaService();

    const fetch = async () => {
      const seasons = [];
      for (const season of media.tvSeriesInfo.seasons) {
        const episodesData = await imdbService.episodes(media.id, season);
        seasons.push({
          season: parseInt(season),
          episodes: episodesData.sort(
            (a, b) => parseInt(a.episodeNumber) - parseInt(b.episodeNumber)
          ),
        });
      }

      setSeasons(seasons.sort((a, b) => a.season - b.season));

      const downloads = await mediaService.downloads(media.id);
      if (downloads?.length) {
        setDownloads(downloads);

        const selectedEpisodes = downloads.map((x) => x.downloadKey);
        setSelectedEpisodes(selectedEpisodes);

        const processedEpisodes = downloads
          .filter((x) => x.state !== "Unknown")
          .map((x) => x.downloadKey);
        setBlockedEpisodes(processedEpisodes);
      }

      setIsLoaded(true);
    };

    fetch();
  }, [media]);

  if (!isLoaded) {
    return <Loader />;
  }

  const handleSeasonChange = (checked, season) => {
    season.episodes.forEach((episode) => {
      handleEpisodeChange(checked, episode);
    });
  };

  const handleEpisodeChange = (checked, episode) => {
    const key = generateEpisodeDownloadKey(episode);
    if (checked) {
      setSelectedEpisodes((prev) => [...prev, key]);
    } else {
      setSelectedEpisodes((prev) => prev.filter((x) => x !== key));
    }
  };

  const generateEpisodeDownloadKey = (episode) => {
    return `S${episode.seasonNumber.padStart(
      2,
      "0"
    )}E${episode.episodeNumber.padStart(2, "0")}_${getDate(
      episode.released
    ).toLocaleDateString("nl-NL")}`;
  };

  const generateSeasonKey = (season) => {
    return generateSeasonKeyByNumber(season.season);
  };

  const generateSeasonKeyByNumber = (season) => {
    const seasonNumber = `S${season.toString().padStart(2, "0")}`;
    return `${seasonNumber}_COMPLETE`;
  };

  const getDate = (released) => {
    const date = new Date(released.replace(".", ""));
    return date;
  };

  const handleSave = async () => {
    const downloads = selectedEpisodes
      .filter((x) => !/^S\d{2}_COMPLETE$/.test(x))
      .map((x) => {
        const dateParts = x.split("_")[1].split("-");

        const info = x.split("_")[0];
        const seasonStr = info.substring(1, 3);
        const episodeSrt = info.substring(4, 6);

        const season = parseInt(seasonStr);
        const episode = parseInt(episodeSrt);

        return {
          key: x,
          query: `${media.title} ${info}`,
          isFullSeason: false,
          releaseDate: new Date(
            `${dateParts[1]}-${dateParts[0]}-${dateParts[2]}`
          ),
          additionalDownloadLabels: `NAME:${media.title.replace(
            ",",
            ""
          )}, SEASON:${season}, EPISODE:${episode}`,
          parentDownloadKey: generateSeasonKeyByNumber(season),
        };
      });

    const now = new Date();
    for (const season of seasons) {
      const seasonNumber = `S${season.season.toString().padStart(2, "0")}`;
      const key = generateSeasonKey(season);

      if (
        season.episodes.every((x) =>
          selectedEpisodes.includes(generateEpisodeDownloadKey(x))
        ) &&
        !season.episodes.some((x) => new Date(x.releaseDate) > now)
      ) {
        downloads.push({
          key: key,
          isFullSeason: true,
          query: `${media.title} ${seasonNumber} complete`,
          additionalDownloadLabels: `NAME:${media.title.replace(
            ",",
            ""
          )}, SEASON:${seasonNumber}, COMPLETE:true`,
        });
      }
    }

    const mediaService = new MediaService();
    mediaService
      .setDownloads(media.id, {
        downloads: downloads,
      })
      .then((data) => {
        hideModal(data);
      });
  };

  const openSettings = (episodeKey) => {
    const download = downloads.find((x) => x.downloadKey === episodeKey);

    showModal(
      `Download instellingen: ${media.title}`,
      <DownloadSettingsModal download={download} />,
      () => {
        setTimeout(() => {
          showModal(
            "Download instellingen",
            <DownloadSerieModal media={media} />
          );
        }, 500);
      }
    );
  };

  const openHistory = (episodeKey) => {
    const download = downloads.find((x) => x.downloadKey === episodeKey);

    showModal(
      "Download geschiedenis",
      <DownloadHistoryModal download={download} />
    );
  };

  const seasonDisabled = (season) => {
    if (!blockedEpisodes?.length) {
      return false;
    }

    const key = generateSeasonKey(season);
    const seasonDownload = blockedEpisodes.find((x) => x === key);

    if (seasonDownload) {
      return true;
    }

    return false;
  };

  return (
    <div className={styles["download-settings"]}>
      <div className={styles.list}>
        <ul className={styles.seasons}>
          {seasons.map((season, index) => {
            const seasonKey = generateSeasonKey(season);

            const seasonDownload = downloads.find(
              (x) => x.downloadKey === seasonKey
            );

            return (
              <li key={index} className={styles.season}>
                <input
                  type="checkbox"
                  id={`season_${season.season}`}
                  onChange={(e) => handleSeasonChange(e.target.checked, season)}
                  disabled={seasonDisabled(season)}
                  checked={season.episodes.every((x) =>
                    selectedEpisodes.includes(generateEpisodeDownloadKey(x))
                  )}
                />
                <label
                  className={styles.season}
                  htmlFor={`season_${season.season}`}
                >
                  Seizoen {season.season} &nbsp;
                  {seasonDownload && (
                    <>
                      <button
                        className={`btn small ${styles.settings}`}
                        disabled={seasonDisabled(season)}
                        onClick={() => openSettings(seasonKey)}
                      >
                        settings
                      </button>

                      {seasonDownload && seasonDownload.lastSearchDate ? (
                        <button
                          className={`btn small ${styles.history}`}
                          disabled={
                            seasonDisabled(season) &&
                            seasonDownload.state === "MovedToPlex"
                          }
                          onClick={() => openHistory(seasonKey)}
                        >
                          Geschiedenis
                        </button>
                      ) : null}
                    </>
                  )}
                </label>

                <ul className={styles.episodes}>
                  {season.episodes.map((episode, index) => {
                    const episodeKey = generateEpisodeDownloadKey(episode);

                    const now = new Date();
                    const date = getDate(episode.released);
                    const download = downloads.find(
                      (x) => x.downloadKey === episodeKey
                    );

                    return (
                      <li key={index} className={styles.episode}>
                        <input
                          type="checkbox"
                          id={`season_${season.season}_episode_${episode.episodeNumber}`}
                          onChange={(e) =>
                            handleEpisodeChange(e.target.checked, episode)
                          }
                          checked={selectedEpisodes.includes(episodeKey)}
                          disabled={
                            blockedEpisodes.some((x) => x === episodeKey) ||
                            (seasonDisabled(season) &&
                              seasonDownload.state === "MovedToPlex")
                          }
                        />
                        <label
                          htmlFor={`season_${season.season}_episode_${episode.episodeNumber}`}
                        >
                          {episode.episodeNumber} - {episode.title}{" "}
                          {date > now && (
                            <span className={styles.badge}>
                              {date.toLocaleDateString("nl-NL", {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              })}
                            </span>
                          )}
                        </label>
                        {!(
                          seasonDisabled(season) &&
                          seasonDownload.state === "MovedToPlex"
                        ) &&
                          selectedEpisodes.includes(episodeKey) &&
                          download && (
                            <>
                              <button
                                className={`btn small ${styles.settings}`}
                                disabled={blockedEpisodes.some(
                                  (x) => x === episodeKey
                                )}
                                onClick={() => openSettings(episodeKey)}
                              >
                                settings
                              </button>

                              {download && download.lastSearchDate ? (
                                <button
                                  className={`btn small ${styles.history}`}
                                  disabled={blockedEpisodes.some(
                                    (x) => x === episodeKey
                                  )}
                                  onClick={() => openHistory(episodeKey)}
                                >
                                  Geschiedenis
                                </button>
                              ) : null}
                            </>
                          )}
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>

      <div className={styles.actions}>
        <button className={styles.cancel} onClick={() => hideModal(null)}>
          Annuleren
        </button>
        <button className={styles.save} onClick={handleSave}>
          Opslaan
        </button>
      </div>
    </div>
  );
};

export default DownloadSerieModal;
