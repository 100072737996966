import axios from "axios";
import auth0 from "auth0-js";

const webAuth = auth0.WebAuth;

class ApiService {
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.axiosInstance = axios.create({ baseURL: this.baseURL });

    this.axiosInstance.interceptors.request.use(function (config) {
      const storeToken = localStorage.getItem("access_token");
      if (!storeToken) {
        return config;
      }

      config.headers.Authorization = `Bearer ${storeToken}`;
      return config;
    });

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("access_token");
          webAuth.logout({
            returnTo: window.location.origin,
            domain: "maikelvdb.eu.auth0.com",
            clientID: "WI86tyv0umWBunLNfWDD2eoYLfZ8Voxr",
          });
          window.location.reload();
          return;
        }
        return error;
      }
    );
  }

  async get(path, params = {}) {
    try {
      const response = await this.axiosInstance.get(path, { params });
      return response.data;
    } catch (error) {
      console.error(`[ApiService] get request to ${path} failed: `, error);
      throw error;
    }
  }

  async post(path, data) {
    try {
      const response = await this.axiosInstance.post(path, data);
      return response.data;
    } catch (error) {
      console.error(`[ApiService] post request to ${path} failed: `, error);
      throw error;
    }
  }

  async put(path, data) {
    try {
      const response = await this.axiosInstance.put(path, data);
      return response.data;
    } catch (error) {
      console.error(`[ApiService] put request to ${path} failed: `, error);
      throw error;
    }
  }

  async delete(path) {
    try {
      const response = await this.axiosInstance.delete(path);
      return response.data;
    } catch (error) {
      console.error(`[ApiService] delete request to ${path} failed: `, error);
      throw error;
    }
  }
}

export default ApiService;
