import React, { useEffect, useRef, useState } from "react";
import classes from "./Modal.module.scss";
import { useModal } from "./useModal";
import useClickOutside from "../../hooks/useClickOutside";

const Modal = ({ onChange }) => {
  const [render, setRender] = useState(false);
  const [shown, setShown] = useState(false);
  const { modalState, hideModal } = useModal();
  const modalRef = useRef(null);
  useClickOutside(modalRef, () => handleHideClick(), modalState.showModal);

  useEffect(() => {
    if (modalState.showModal) {
      onChange(true);
      setRender(true);
      setTimeout(() => setShown(true), 100);
      return;
    }

    onChange(false);
    setShown(false);
    setTimeout(() => setRender(false), 300);
  }, [modalState.showModal]);

  const handleHideClick = () => {
    hideModal();
  };

  if (!render) {
    return <></>;
  }

  return (
    <div
      className={`${classes["modal-wrapper"]} ${shown ? classes.shown : ""}`}
    >
      <div ref={modalRef} className={classes.modal}>
        <div className={classes.close} onClick={handleHideClick}></div>

        <h3>{modalState.title}</h3>

        <div className={`${classes.content} custom-scroll`}>
          {modalState.content}
        </div>
      </div>
    </div>
  );
};

export default Modal;
