import { useEffect, useState } from "react";
import styles from "./Navbar.module.scss";
import UsersService from "../../../services/UsersService";
import DownloadStateLogService from "../../../services/DownloadStateLogService";
import { Link } from "react-router-dom";
import { MenuItems } from "../Menu/MenuItems";
import { useAuth0 } from "@auth0/auth0-react";

const Navbar = () => {
  const [user, setUser] = useState(null);
  const { logout } = useAuth0();
  const [notifications, setNotifications] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    new UsersService().current().then((curr) => {
      setUser(curr);
    });

    new DownloadStateLogService().working().then((data) => {
      if (!data) {
        return;
      }

      const top10 = data
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 10);
      setNotifications(top10);
    });
  }, []);

  const handleSignoutClick = () => {
    localStorage.removeItem("access_token");
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <div className={styles.navbar}>
      <Link to="/" className={styles["brand-name"]}>
        Media
      </Link>

      <div
        className={`${styles.burger} mobile-only`}
        onClick={() => setIsMenuOpen((prev) => !prev)}
      >
        <span className="material-symbols-outlined">
          {isMenuOpen ? "close" : "menu"}
        </span>
      </div>

      <ul className={styles.menu}>
        {notifications?.length ? (
          <li className={styles.notifications}>
            <span className="material-symbols-outlined">notifications</span>

            <div className={styles["notification-list"]}>
              {notifications.map((item) => (
                <div key={item.id} className={styles["notification-list-item"]}>
                  <div className={styles.date}>
                    {new Date(item.createdAt).toLocaleString("nl-NL")}
                  </div>
                  <div className={styles["media-name"]}>
                    {item.mediaItemDownload.mediaItem.title}
                  </div>
                  <div className={styles["state-badge"]}>
                    <span
                      className={`media-badge ${item.newState.toLowerCase()}`}
                    ></span>
                  </div>
                </div>
              ))}
            </div>
          </li>
        ) : (
          <></>
        )}
        {user && (
          <li
            className={styles["user"]}
            style={{ "--avatar": `url(${user.picture})` }}
          >
            {user.firstName} {user.name}
            <div className={styles["user-menu"]}>
              <div className={styles["user-menu__item"]}>
                <span className={styles.link} onClick={handleSignoutClick}>
                  Uitloggen
                </span>
              </div>
            </div>
          </li>
        )}
      </ul>

      <div
        className={`${styles["mobile-menu"]} ${
          isMenuOpen ? styles.shown : ""
        } mobile-only`}
      >
        {user && (
          <div
            className={styles["user"]}
            style={{ "--avatar": `url(${user.picture})` }}
          >
            <div className={styles.name}>
              {user.firstName} {user.familyName}
            </div>

            <div className={styles["sign-out"]} onClick={handleSignoutClick}>
              <span className="material-symbols-outlined">logout</span>
            </div>
          </div>
        )}

        {MenuItems.map((item) => (
          <Link
            key={item.index}
            to={item.path}
            onClick={() => setIsMenuOpen(false)}
          >
            <div className={styles.icon}>
              <span className="material-symbols-outlined">{item.icon}</span>
            </div>
            <div className={styles.name}>{item.label}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Navbar;
