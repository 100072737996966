import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Menu.module.scss";
import { NavLink, createSearchParams, useNavigate } from "react-router-dom";
import useQuery from "../../../hooks/useQuery";
import { setSearchValue } from "../../../../features/SearchSlice";
import { MenuItems } from "./MenuItems";
import { useAuth0 } from "@auth0/auth0-react";

const Menu = () => {
  const searchValue = useSelector((state) => state.search.value);
  const [searchInterval, setSearchTimeout] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const query = useQuery();
  const { logout } = useAuth0();

  useEffect(() => {
    if (!inputRef) {
      return;
    }

    const search = query.get("q");
    dispatch(setSearchValue(search));
  }, [inputRef, query, dispatch]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = searchValue;
    }
  }, [searchValue]);

  const signout = () => {
    localStorage.removeItem("access_token");
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const handleSearch = (e) => {
    if (searchInterval) {
      window.clearTimeout(searchInterval);
    }

    setSearchTimeout(
      window.setTimeout(() => {
        const value = e.target.value;
        navigate({
          pathname: "/search",
          search: createSearchParams({
            q: value,
          }).toString(),
        });
      }, 500)
    );
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      navigate({
        pathname: "/search",
        search: createSearchParams({
          q: searchValue,
        }).toString(),
      });
    }
  };

  return (
    <div className={styles.menu}>
      <div className={styles.search}>
        <input
          type="text"
          placeholder="Zoeken..."
          defaultValue={searchValue}
          ref={inputRef}
          onChange={handleSearch}
          onKeyUp={handleEnter}
        />
      </div>

      <ul className={styles["items"]}>
        {MenuItems.map((x) => (
          <li key={x.index} className={styles["item"]}>
            <NavLink
              className={({ isActive }) =>
                `menu-link-icon ${x.icon} ${isActive ? styles.active : ""}`
              }
              to={x.path}
            >
              {x.label}
            </NavLink>
          </li>
        ))}
        <li className={styles["item"]} onClick={signout}>
          <span className={`${styles.link} ${styles.signout}`}>Uitloggen</span>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
