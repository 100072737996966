import ApiService from "./ApiService";

class DownloadsService {
  constructor() {
    this.api = new ApiService(`${process.env.REACT_APP_API_URL}/Downloads`);
  }

  setAlternativeDownload = async (possibleDownloadId) => {
    return await this.api.put(`/PossibleDownloads/${possibleDownloadId}/Use`);
  };

  addAlternativeDownload = async (downloadId, url) => {
    return await this.api.post(`${downloadId}/PossibleDownloads`, { url });
  };
}

export default DownloadsService;
