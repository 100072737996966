import ApiService from "./ApiService";

class DownloadStateLogService {
  constructor() {
    this.api = new ApiService(
      `${process.env.REACT_APP_API_URL}/DownloadStateUpdateLogs`
    );
  }

  get = async () => {
    return await this.api.get();
  };

  working = async () => {
    return await this.api.get("/Working");
  };
}

export default DownloadStateLogService;
