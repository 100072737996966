import { useContext } from "react";
import { ModalContext } from "./ModalContext";

export const useModal = () => {
  const { state, dispatch } = useContext(ModalContext);

  const showModal = (title, content, callback) => {
    dispatch({
      type: "SHOW_MODAL",
      payload: { title, content, callback },
    });
  };

  const hideModal = (...args) => {
    if (state.callback) {
      state.callback(...args);
    }

    dispatch({ type: "HIDE_MODAL" });
    setTimeout(() => dispatch({ type: "CLEAR_MODAL" }), 300);
  };

  return { showModal, hideModal, modalState: state };
};
