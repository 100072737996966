import styles from "./DownloadSettings.module.scss";
import { useEffect, useMemo, useState } from "react";
import DownloadSerieModal from "./download-serie-modal/DownloadSerieModal";
import slugify from "slugify";
import { useModal } from "../../../shared/components/modal/useModal";
import MediaService from "../../../shared/services/MediaService";
import DownloadSettingsModal from "./download-settings-modal/DownloadSettingsModal";
import DownloadHistoryModal from "./download-history-modal/DownloadHistoryModal";

const DownloadSettings = ({
  item,
  downloads,
  isWatched,
  setDownloads,
  setIsWatched,
}) => {
  const mediaService = useMemo(() => new MediaService(), []);
  const [isChangingDownload, setIsChangingDownload] = useState(false);
  const { showModal } = useModal();

  useEffect(() => {
    if (!isChangingDownload) {
      return;
    }

    const fetch = async () => {
      if (item.type.toLowerCase() === "movie") {
        if (downloads?.length) {
          await mediaService.deleteDownloads(item.id);
          setDownloads([]);
          setIsChangingDownload(false);
          return;
        }

        const key = slugify(item.title, { lower: true });
        const date = new Date(item.releaseDate);
        mediaService
          .setDownloads(item.id, {
            downloads: [
              {
                key,
                query: `${item.title} ${date.getFullYear()}`,
                releaseDate: date,
                additionalDownloadLabels: `NAME:${item.title.replace(",", "")}`,
              },
            ],
          })
          .then((result) => {
            setDownloads(result);
            setIsWatched(true);
            setIsChangingDownload(false);
          });
        return;
      }

      showModal(
        "Download instellingen",
        <DownloadSerieModal media={item} />,
        handleSeriesClose
      );
    };

    fetch();
  }, [
    isChangingDownload,
    mediaService,
    item,
    downloads,
    setDownloads,
    setIsWatched,
  ]);

  if (!isWatched) {
    return <></>;
  }

  const handleDownloadClick = async () => {
    if (isChangingDownload) {
      return;
    }

    setIsChangingDownload(true);
  };

  const handleSeriesClose = (downloads) => {
    if (!downloads) {
      setIsChangingDownload(false);
      return;
    }

    setDownloads(downloads);
    setIsWatched(true);
    setIsChangingDownload(false);
  };

  const manageDownload = () => {
    if (isChangingDownload) {
      return;
    }

    if (!item || item.type.toLowerCase() !== "movie") {
      showModal(
        "Download instellingen",
        <DownloadSerieModal media={item} />,
        handleSeriesClose
      );
      return;
    }

    const download = downloads[0];
    if (!download) {
      return;
    }

    showModal(
      `Download instellingen: ${item.title}`,
      <DownloadSettingsModal download={download} />
    );
  };

  const now = new Date();

  const getDate = (date) => {
    if (!date) {
      return null;
    }

    return new Date(date);
  };

  const openHistory = () => {
    if (isChangingDownload) {
      return;
    }

    const download = downloads[0];
    showModal(
      "Download geschiedenis",
      <DownloadHistoryModal download={download} />
    );
  };

  return (
    <div className={styles.settings}>
      <div className={styles.download}>
        {!downloads?.length && (
          <div
            className={`${styles["add-download"]} ${
              isChangingDownload && styles.loading
            }`}
            onClick={handleDownloadClick}
          >
            Toevoegen op downloadlijst
          </div>
        )}

        {downloads?.length &&
        downloads.some((x) => x.state === "Unknown" || x.state === "Queued") ? (
          <div
            className={`${styles["download-splitter"]} ${
              isChangingDownload && styles.loading
            }`}
          >
            <div className={styles["manage-download"]} onClick={manageDownload}>
              Download instellingen
            </div>
            {item.type.toLowerCase() === "movie" &&
            downloads.some((x) => !!x.lastSearchDate) ? (
              <div className={styles["history-download"]} onClick={openHistory}>
                <span className="material-symbols-outlined">search</span>
              </div>
            ) : null}
            <div
              className={styles["remove-download"]}
              onClick={handleDownloadClick}
            >
              <span className="material-symbols-outlined">close</span>
            </div>
          </div>
        ) : null}

        {item.type.toLowerCase() === "movie" &&
        downloads?.length &&
        downloads[0].state === "Unknown" &&
        !!getDate(downloads[0].lastSearchDate) &&
        now > getDate(downloads[0].lastSearchDate) ? (
          <div className={styles["search-date"]}>
            Gezocht op:{" "}
            <strong>
              {getDate(downloads[0].lastSearchDate).toLocaleDateString("nl-NL")}{" "}
              {getDate(downloads[0].lastSearchDate).toLocaleTimeString("nl-NL")}
            </strong>
          </div>
        ) : null}

        {item.type.toLowerCase() === "movie" &&
        downloads?.length &&
        downloads[0].state !== "Unknown" ? (
          <span
            className={`media-badge ${downloads[0].state.toLowerCase()}`}
          ></span>
        ) : null}

        {item.type.toLowerCase() !== "movie" &&
        downloads?.length &&
        !downloads.some(
          (x) => x.state === "Unknown" || x.state === "Queued"
        ) ? (
          <span
            className={`media-badge ${downloads[0].state.toLowerCase()}`}
          ></span>
        ) : null}
      </div>
    </div>
  );
};

export default DownloadSettings;
