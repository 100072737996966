import { useEffect, useState } from "react";
import styles from "./Search.module.scss";
import Loader from "../../shared/components/loader/Loader";
import ImdbService from "../../shared/services/ImdbService";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Search = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [results, setResults] = useState([]);
  const searchValue = useSelector((state) => state.search.value);
  const imdbService = new ImdbService();

  useEffect(() => {
    if (!searchValue) {
      setResults([]);
      return;
    }

    setIsLoaded(false);

    imdbService.search(searchValue).then((data) => {
      setResults(data);
      setIsLoaded(true);
    });
  }, [searchValue]);

  if (!isLoaded) {
    return <Loader />;
  }

  if (!results?.length) {
    return (
      <div className={styles["search-results"]}>
        <div className={styles["no-results"]}>Geen resultaten gevonden...</div>
      </div>
    );
  }

  return (
    <div className={styles["search-results"]}>
      {results.map((result) => (
        <div key={result.id} className={styles["result-card"]}>
          <Link to={`/media/${result.id}`} className={styles["link"]}></Link>
          <div
            className={styles["cover"]}
            style={{ backgroundImage: `url(${result.image})` }}
          >
            {result.image ? (
              <div className={styles["blur"]}>
                <img src={result.image} alt={result.title} />
              </div>
            ) : (
              <div className={styles["no-img"]}></div>
            )}
          </div>
          <div className={styles["title"]}>{result.title}</div>
        </div>
      ))}
    </div>
  );
};

export default Search;
