import { useCallback, useEffect, useState } from "react";
import styles from "./Login.module.scss";
import Youtube from "react-youtube";
import ImdbService from "../../shared/services/ImdbService";
import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom";

const Login = () => {
  const [items, setItems] = useState([]);
  const [selectedIdx, setSelectedIdx] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { loginWithRedirect, logout } = useAuth0();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    new ImdbService().boxOffice().then((response) => {
      setItems(response);
    });
  }, []);

  useEffect(() => {
    if (!searchParams.has("error_description")) {
      setErrorMessage(null);
      return;
    }

    logout({ logoutParams: {} });

    setErrorMessage(searchParams.get("error_description"));
  }, [searchParams, logout]);

  const handleYoutubeEnded = useCallback(() => {
    var idx = Math.floor(Math.random() * items.length);
    setSelectedIdx(idx);
  }, [items]);

  useEffect(() => {
    if (!items || !items?.length) {
      return;
    }

    if (selectedIdx === null) {
      handleYoutubeEnded();
      return;
    }

    const id = items[selectedIdx].id;
    new ImdbService().youtubeTrailer(id).then((response) => {
      if (!response || !response.videoId) {
        handleYoutubeEnded();
        return;
      }

      setVideoId(response.videoId);
    });
  }, [items, selectedIdx, handleYoutubeEnded]);

  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      mute: 1,
      controls: 0,
      disablekb: 1,
      fs: 0,
      playerVars: {
        playsinline: 1,
      },
    },
  };

  return (
    <div className={styles["login-screen"]}>
      {errorMessage && (
        <div className={styles["error-message"]}>{errorMessage}</div>
      )}
      {videoId && (
        <div className={styles["youtube-background"]}>
          <Youtube videoId={videoId} opts={opts} onEnd={handleYoutubeEnded} />
          <div className={styles["youtube-overlay"]}></div>
        </div>
      )}

      <div className={styles["login-wrapper"]}>
        {videoId && (
          <div
            className={styles["info"]}
            style={{ backgroundImage: `url(${items[selectedIdx].image})` }}
          ></div>
        )}
        <div
          className={styles["login-button"]}
          onClick={() => loginWithRedirect()}
        >
          <span className={styles.text}>Login</span>
        </div>
      </div>
    </div>
  );
};

export default Login;
