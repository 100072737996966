import styles from "./DownloadHistoryModal.module.scss";
import Table from "../../../../shared/components/table/Table";
import { useEffect, useMemo, useState } from "react";
import DownloadsService from "../../../../shared/services/DownloadsService";
import ScrappersService from "../../../../shared/services/ScrappersService";
import Input from "../../../../shared/components/form-items/input/Input";
import Alert from "../../../../shared/components/alert/Alert";
import Loader from "../../../../shared/components/loader/Loader";

const DownloadHistoryModal = ({ download }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [current, setCurrent] = useState(download);
  const [canSet, setCanSet] = useState(true);
  const [url, setUrl] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [scrappers, setScrappers] = useState([]);
  const downloadsService = useMemo(() => new DownloadsService(), []);
  const scrappersService = useMemo(() => new ScrappersService(), []);

  useEffect(() => {
    setIsLoaded(false);

    const getScrappers = async () => {
      const scrappers = await scrappersService.get();
      setScrappers(scrappers);

      if (!!current) {
        setIsLoaded(true);
      }
    };

    getScrappers();
  }, [scrappersService]);

  useEffect(() => {
    setCurrent(download);
  }, [download]);

  useEffect(() => {
    setIsLoaded(false);

    if (!current) {
      return;
    }

    const value = current?.possibleDownloads?.some((x) => !!x.useThisDownload);
    setCanSet(!value);

    if (scrappers?.length > 0) {
      setIsLoaded(true);
    }
  }, [current]);

  if (!isLoaded) {
    return <Loader />;
  }

  const columns = [
    {
      value: (row) => ({
        href: `${row.baseUrl}${row.pageUrl}`,
        target: "_blank",
        text: row.name,
      }),
      className: () => styles["name-cell"],
      title: "Naam",
      type: "link",
      width: "1fr",
    },
    {
      value: (row) => row.seeders,
      type: "number",
      title: "Seeders",
      width: "90px",
    },
    {
      value: (row) => `${(row.downloadSizeMb / 1000).toFixed(2)} GB`,
      type: "float",
      title: "Grote",
      width: "90px",
      sort: {
        sortable: true,
        value: (row) => row.downloadSizeMb,
      },
    },
    {
      value: (row) => row.points,
      type: "number",
      title: "Punten",
      width: "90px",
    },
    {
      value: (row) => {
        if (!canSet) {
          return row.useThisDownload ? (
            <span className="material-symbols-outlined">check</span>
          ) : (
            <span className="material-symbols-outlined">close</span>
          );
        }

        return (
          <button
            className="btn small save"
            onClick={() => setAlternativeDownload(row)}
          >
            Download
          </button>
        );
      },
      type: "button",
      className: (row) =>
        row.useThisDownload ? styles["check"] : styles["close"],
      title: "",
      width: canSet ? "95px" : "42px",
    },
  ];

  const setAlternativeDownload = async (row) => {
    await downloadsService.setAlternativeDownload(row.id);

    row.useThisDownload = true;
    setCanSet(false);
  };

  const validScrapperUrl = () => {
    const uri = getCleanUrl(url);
    const allowedUrl = scrappers.map((x) => getCleanUrl(x.baseUrl));

    return allowedUrl.some((x) => x === uri);
  };

  const getCleanUrl = (url) => {
    const uri = new URL(url);
    const domain = uri.origin.split("/")[2];
    const baseUrl = domain.replace("www.", "");

    return `${baseUrl}`.toLowerCase();
  };

  const AddPossibleDownload = async () => {
    if (!validScrapperUrl()) {
      setError(
        <div>
          Only urls below are allowed: <br />
          <ul>
            {scrappers.map((x) => (
              <li key={x.name}>
                {x.name} ({x.baseUrl})
              </li>
            ))}
          </ul>
        </div>
      );

      setTimeout(() => setError(null), 5000);
      return;
    }

    const download = await downloadsService.addAlternativeDownload(
      current.id,
      url
    );

    setError(null);

    setSuccess("Download url toegevoegd");
    setTimeout(() => setSuccess(null), 5000);

    setUrl("");
    setCurrent(download);
  };

  return (
    <div className={styles["download-history"]}>
      <Table data={current.possibleDownloads} columns={columns} />

      {!current.possibleDownloads.some((x) => x.useThisDownload) ? (
        <div className={styles.form}>
          {!!error ? (
            <Alert type="error" className={styles.alert}>
              {error}
            </Alert>
          ) : null}

          <Input label="Download url toevoegen" value={url} onChange={setUrl} />
          <button
            className="btn save"
            onClick={AddPossibleDownload}
            disabled={!url}
          >
            Toevoegen
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default DownloadHistoryModal;
