import { useEffect } from "react";
import styles from "./Footer.module.scss";
import { useState } from "react";
import AgentsInfoService from "../../../services/AgentsInfoService";

const Footer = () => {
  const [agentInfo, setAgentInfo] = useState();
  const [intervalMethod, setIntervalMethod] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIntervalMethod(checkAgentInfo, 60000);

    return () => {
      if (intervalMethod) {
        window.clearInterval(intervalMethod);
        setIntervalMethod(null);
        setAgentInfo(null);
        setIsLoaded(false);
      }
    };
  }, []);

  const checkAgentInfo = () => {
    const agentsInfoService = new AgentsInfoService();
    agentsInfoService.get().then((data) => {
      setAgentInfo(data);
      setIsLoaded(true);
    });
  };

  const formatTime = (minutes) => {
    let totalSeconds = Math.round(minutes * 60);

    const years = Math.floor(totalSeconds / (60 * 60 * 24 * 365));
    totalSeconds -= years * 60 * 60 * 24 * 365;

    const months = Math.floor(totalSeconds / (60 * 60 * 24 * 30));
    totalSeconds -= months * 60 * 60 * 24 * 30;

    const weeks = Math.floor(totalSeconds / (60 * 60 * 24 * 7));
    totalSeconds -= weeks * 60 * 60 * 24 * 7;

    const days = Math.floor(totalSeconds / (60 * 60 * 24));
    totalSeconds -= days * 60 * 60 * 24;

    const hours = Math.floor(totalSeconds / (60 * 60));
    totalSeconds -= hours * 60 * 60;

    const mins = Math.floor(totalSeconds / 60);
    totalSeconds -= mins * 60;

    let result = "";
    if (years > 0) result += years + " year" + ", ";
    if (months > 0)
      result += months + (months > 1 ? "maanden" : "maand") + ", ";
    if (weeks > 0) result += weeks + (weeks > 1 ? "weken" : "week") + ", ";
    if (days > 0) result += days + (days > 1 ? "dagen" : "dag") + ", ";
    if (hours > 0) result += hours + (hours > 1 ? "uren" : "uur") + ", ";
    if (minutes > 0) result += minutes + (minutes > 1 ? "minuten" : "minuut");
    if (totalSeconds > 0)
      result += totalSeconds + (totalSeconds > 1 ? "seconden" : "seconde");

    result = result.replace(/, $/, "");

    return result;
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.agentInfo}>
        {isLoaded && agentInfo && agentInfo && (
          <>
            <div
              className={`${styles.agentStatus} ${
                !agentInfo.info && styles.noInfo
              }`}
            >
              {agentInfo.connected ? (
                <div className={styles.connected}>verbonden</div>
              ) : (
                <div
                  className={styles.disconnected}
                  title={
                    agentInfo.disconnectedTime
                      ? `${formatTime(agentInfo.disconnectedTime)}`
                      : "Nooit verbonden"
                  }
                >
                  geen verbinding
                </div>
              )}
            </div>

            {agentInfo.info && (
              <div className={styles.diskspace}>
                {Math.round(agentInfo.info.freeSpace)}GB{" "}
                <span className={styles.total}>
                  / {Math.round(agentInfo.info.totalSpace)}GB
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </footer>
  );
};

export default Footer;
