import { useEffect, useState } from "react";
import styles from "./MediaByType.module.scss";
import MediaService from "../../../shared/services/MediaService";
import Loader from "../../../shared/components/loader/Loader";
import MediaItem from "../media-item/MediaItem";

const MediaByType = ({ type }) => {
  const [selectedType, setSelectedType] = useState(type);
  const [items, setItems] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setSelectedType(type);
  }, [type]);

  useEffect(() => {
    new MediaService().get(type).then((response) => {
      const typeItems = response.filter((x) => x.type === type);

      setItems(typeItems);
      setIsLoaded(true);
    });
  }, [selectedType]);

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <div className={styles.items}>
      {items?.length ? (
        items.map((item) => <MediaItem key={item.id} item={item} />)
      ) : (
        <span className={styles["no-items"]}>Geen items gevonden</span>
      )}
    </div>
  );
};

export default MediaByType;
