import { useEffect, useState } from "react";
import Login from "./components/login/Login";
import Layout from "./shared/components/Layout/Layout";
import AppRoutes from "./App.Routes";
import { Route, Routes } from "react-router-dom";
import OneSignal from "react-onesignal";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "./shared/components/loader/Loader";

export async function runOneSignal() {
  return OneSignal.init({
    appId: "48f3dcb2-49e9-42c5-b12e-efbda9d07eb8",
    safari_web_id: "web.onesignal.auto.01b20842-ed7c-48c4-bd42-e78491d78625",
    notifyButton: {
      enable: true,
    },
    allowLocalhostAsSecureOrigin: true,
  });
}

const App = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const [fullyAuthenticated, setFullyAuthenticated] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const fetch = async () => {
      const accessToken = await getAccessTokenSilently({
        audience: `https://maikelvdb.eu.auth0.com/api/v2/`,
        scope: "read:current_user",
      });

      if (!accessToken) {
        localStorage.removeItem("access_token");
        setFullyAuthenticated(false);
        return;
      }

      localStorage.setItem("access_token", accessToken);
      setFullyAuthenticated(true);
    };

    fetch();
  }, [isAuthenticated, getAccessTokenSilently]);

  if (isLoading) {
    return <Loader />;
  }

  if (!fullyAuthenticated) {
    return <Login />;
  }

  return (
    <Layout>
      <Routes>
        {AppRoutes.map((route, index) => {
          const { element, ...rest } = route;
          return <Route key={index} {...rest} element={element} />;
        })}
      </Routes>
    </Layout>
  );
};

export default App;
