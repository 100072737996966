import { useEffect, useState } from "react";
import baseStyles from "../Table.module.scss";
import styles from "./TableHeader.module.scss";
import TableHeaderFilters from "./table-header-filters/TableHeaderFilters";

const TableHeader = ({
  columns,
  data,
  sortOrder,
  sortField,
  sortChange,
  filtersChanged,
}) => {
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    filtersChanged(filters);
  }, [filters]);

  const getStyles = (column) => {
    if (column.colSpan) {
      return { gridColumn: `span ${column.colSpan}` };
    }
  };

  const onSortChange = (column) => {
    if (!column.sort || !column.sort.sortable) {
      return;
    }

    if (sortField === column) {
      if (sortOrder === SortOrder.ASCENDING) {
        sortChange(column, SortOrder.DESCENDING);
      } else if (sortOrder === SortOrder.DESCENDING) {
        sortChange(null, SortOrder.NONE);
      } else {
        sortChange(column, SortOrder.ASCENDING);
      }
    } else {
      sortChange(column, SortOrder.ASCENDING);
    }
  };

  const filterChanged = (filters, column) => {
    setFilters((prev) => [
      ...prev.filter((x) => x.column !== column),
      ...filters.map((x) => ({ ...x, column })),
    ]);
  };

  return (
    <div className={baseStyles.header}>
      {columns
        .filter((x) => !x.skipHeader)
        .map((column, index) => (
          <div
            key={index}
            className={`${baseStyles.cell} ${
              !column.title ? baseStyles["no-right-border"] : ""
            }`}
            style={getStyles(column)}
          >
            <div className={styles.label}>
              {column.title}
              {column.sort && column.sort.sortable ? (
                <div
                  className={`${styles.sort} ${styles[sortOrder]} ${
                    sortField === column ? styles.active : ""
                  }`}
                  onClick={() => onSortChange(column)}
                ></div>
              ) : (
                ""
              )}
            </div>
            {column.filters?.length ? (
              <TableHeaderFilters
                column={column}
                data={data}
                filterChanged={(filters) => filterChanged(filters, column)}
                isAlignLeft={columns.length - 4 <= index}
              />
            ) : (
              ""
            )}
          </div>
        ))}
    </div>
  );
};

export default TableHeader;

export const SortOrder = Object.freeze({
  ASCENDING: "ascending",
  DESCENDING: "descending",
  NONE: "none",
});
