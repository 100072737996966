import { useEffect } from "react";
import styles from "./Home.module.scss";
import { useState } from "react";
import MediaService from "../../shared/services/MediaService";
import ImdbService from "../../shared/services/ImdbService";
import Loader from "../../shared/components/loader/Loader";
import { useDispatch } from "react-redux";
import { reset } from "../../features/SearchSlice";
import MediaGroup from "./media-group/MediaGroup";

const Home = () => {
  const mediaService = new MediaService();
  const imdbService = new ImdbService();
  const dispatch = useDispatch();

  const [myItems, setMyItems] = useState(null);
  const [myItemsLoaded, setMyItemsLoaded] = useState(false);

  const [boxOffice, setBoxOffice] = useState(null);
  const [boxOfficeLoaded, setBoxOfficeLoaded] = useState(false);

  const [all, setAll] = useState(null);
  const [allLoaded, setAllLoaded] = useState(false);

  useEffect(() => {
    dispatch(reset());

    mediaService.my().then((data) => {
      if (!data) {
        return;
      }

      const ten = data
        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
        .slice(0, 20);

      setMyItems(ten);
      setMyItemsLoaded(true);
    });

    mediaService.get().then((data) => {
      if (!data) {
        return;
      }

      const all = data
        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
        .slice(0, 20);

      setAll(all);
      setAllLoaded(true);
    });

    imdbService.boxOffice().then((data) => {
      if (!data) {
        return;
      }

      setBoxOffice(data);
      setBoxOfficeLoaded(true);
    });
  }, []);

  if (!myItemsLoaded && !boxOfficeLoaded && !allLoaded) {
    return <Loader />;
  }

  return (
    <div className={styles.home}>
      <MediaGroup
        title="Persoonlijk"
        items={myItems}
        className={styles.left}
        isLoaded={myItemsLoaded}
      />

      <MediaGroup
        title="Alles"
        items={all}
        className={styles.mid}
        isLoaded={allLoaded}
      />

      <MediaGroup
        title="In bioscoop"
        items={boxOffice}
        className={styles.right}
        isLoaded={boxOfficeLoaded}
      />
    </div>
  );
};

export default Home;
